import React from 'react';

const RoundTitle = ({ title }) => {
  return (
    <div className="flex mx-auto my-4 px-4 border-2 rounded-full border-[#d7b375] text-xl text-[#d7b375]">
      <h2>{title}</h2>
    </div>
  );
};

export default RoundTitle;
